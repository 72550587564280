import {Routes} from '@angular/router'

export const routes: Routes = [
  {
    path: 'ansok',
    children: [
      {
        path: '',
        loadComponent: () => import('./loan/blancolan/blancolan.component')
          .then(c => c.BlancolanComponent),
        pathMatch: 'full'
      },
      {
        path: 'svar',
        loadComponent: () => import('./loan/blancolan-res/blanco-loan-res.component')
          .then(c => c.BlancoLoanResComponent)
      }
    ]
  },
  {
    path: '**',
    redirectTo: 'ansok',
    pathMatch: 'full'
  }
]
