// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  loginServiceUrl: 'https://www-test.lana.sparbankensyd.se/api',
  userServiceUrl: 'https://www-test.lana.sparbankensyd.se/service',
  authServiceUrl: 'https://www-test.lana.sparbankensyd.se/service',
  rtUrl: 'https://www-test.lana.sparbankensyd.se/interests',
  domain: 'www-test.lana.sparbankensyd.se'
}
